<template lang="pug">
v-text-field(
  dense
  filled
  clearable
  :value="value"
  @input="onInput($event)"
  @change="onChange()"
  @focus="toggleInputFocusState"
  @blur="toggleInputFocusState"
  @keypress="onKeypress"
  :placeholder="placeholder"
  data-test="search"
  ref="search_input"
  :disabled="disabled"
)
  <template v-slot:prepend-inner>
    v-icon(@click="onSearchIconClick" x-small) $vuetify.icons.searchNew
  </template>
</template>

<script>
export default {
  name: 'Search',

  props: {
    value: {
      required: true
    },
    placeholder: String,
    disabled: Boolean
  },
  data: () => ({
    isInputFocused: false,
    currentInput: null,
    changedInput: null
  }),
  created() {
    this.currentInput = this.value;
    this.changedInput = this.value;
  },
  methods: {
    toggleInputFocusState() {
      this.isInputFocused = !this.isInputFocused;
      this.$emit('onFocusToggle', this.isInputFocused);
    },
    onInput(event) {
      this.currentInput = event;
      this.$emit('input', event)
    },
    onChange() {
      if (this.currentInput !== this.changedInput) {
        this.changedInput = this.currentInput;
        this.$emit('change', this.changedInput)
      }
    },
    onSearchIconClick() {
      this.$refs.search_input.focus();
      this.onChange()
    },
    onKeypress(event) {
      if(event && event.keyCode === 13) {
        this.$emit('enter-key-pressed', this.currentInput);
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .v-input.v-input--is-focused::v-deep {
    input {
      color: $primary-color;
    }
  }
  .v-input::v-deep {
    padding: 0;
    margin: 0;
    input {
      font-size: 14px;
      &::placeholder {
        font-size: 18px;
      }
    }
    .v-icon {
      font-size: 20px;
    }

    .v-input__append-inner {
      margin-top: 4px !important;
    }

    &.v-text-field--filled.v-input--dense > .v-input__control > .v-input__slot {
      min-height: 32px;
      height: 32px;
      padding: 0 8px;
      margin: 0;
      background: $bg-filled;
      input {
        margin: 0;
        padding: 6px 0 8px;
        &::placeholder {
          font-size: 12px;
          font-family: $font;
          font-weight: 600;
          color: $placeholder-color;
        }
      }
      .v-input__prepend-inner {
        margin-top: 9px;
      }
    }
    .v-input__slot {
      margin: 0;
      border-radius: 4px;
      &:before {
        display: none;
      }
    }
    .v-text-field__details {
      display: none;
    }
  }
</style>
